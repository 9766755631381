<template>
  <div class="tradePoint">
    <div class="dataSearchDelayTips">
      {{ $t("tips.dataSearchDelay") }}
    </div>
    <div class="panel-title">
      {{ $t("tradePoint") }}
    </div>

    <div class="search-query-container">
      <div class="search-query">
        <el-form :form="searchForm" v-if="this.userInfo.isMasterEnterprise">
          <el-row :gutter="20">
            <el-col>
              <el-form-item :label="$t('tradeDateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('enterprise')">
                <el-select v-model="searchForm.entList" @change="entChange" filterable clearable style="width: 100%;">
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option
                    v-for="item in slaveEnterprisesDatas"
                    :key="item.enterpriseCode"
                    :label="item.enterpriseName"
                    :value="item.enterpriseCode"
                  >
                    <span style="float: left">{{ item.enterpriseName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.enterpriseCode }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('store')">
                <el-select
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  collapse-tags
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option-group v-for="group in shopList" :key="group.entId" :label="group.entDisplayName">
                    <el-option
                      v-for="item in group.storeList"
                      :key="item.storeId"
                      :label="item.storeName"
                      :value="item.storeId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeKind')">
                <el-select v-model="searchForm.tradeState" style="width: 100%;">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option :label="$t('pointUseSuccess')" value="POINTUSESUCCESS"></el-option>
                  <el-option :label="$t('pointUseRefund')" value="POINTUSEREFUND"></el-option>
                  <el-option :label="$t('pointGiveSuccess')" value="POINTGIVESUCCESS"></el-option>
                  <el-option :label="$t('pointGiveRefund')" value="POINTGIVEREFUND"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('paymentOptions')">
                <el-select
                  v-model="searchForm.tradeType"
                  multiple
                  filterable
                  clearable
                  style="width: 100%;"
                  ref="tradeType"
                  :placeholder="$t('payKindHint')"
                  @change="tradeTypeChange"
                >
                  <el-option v-for="item in tradeTypes" :key="item.category" :label="item.name" :value="item.category">
                    <div class="select-option-with-images">
                      <img class="option-image" :src="getPaymentLogos(item.name)" :alt="item.name" />
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderNo')">
                <el-input clearable v-model="searchForm.orderNo" :placeholder="$t('tradNoHint')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('terminalNo')">
                <el-input clearable v-model="searchForm.deviceNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('transactionId')">
                <el-input
                  clearable
                  v-model="searchForm.transactionId"
                  :placeholder="$t('transactionIdHint')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('bonusType')">
                <el-select v-model="searchForm.pointChargeType" style="width: 100%;">
                  <el-option :label="$t('all')" value="0"></el-option>
                  <el-option :label="$t('consumptionPointsAwarded')" value="1"></el-option>
                  <el-option :label="$t('campaignPoints')" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form :form="searchForm" v-else>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeDateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeKind')">
                <el-select v-model="searchForm.tradeState" style="width: 100%;">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option :label="$t('pointUseSuccess')" value="POINTUSESUCCESS"></el-option>
                  <el-option :label="$t('pointUseRefund')" value="POINTUSEREFUND"></el-option>
                  <el-option :label="$t('pointGiveSuccess')" value="POINTGIVESUCCESS"></el-option>
                  <el-option :label="$t('pointGiveRefund')" value="POINTGIVEREFUND"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('store')">
                <el-select
                  v-if="$settings.newStoreVersion === true"
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option v-for="item in shopList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option-group v-for="group in shopList" :key="group.label" :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('terminalNo')">
                <el-input clearable v-model="searchForm.deviceNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderNo')">
                <el-input clearable v-model="searchForm.orderNo" :placeholder="$t('tradNoHint')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('paymentOptions')">
                <el-select
                  v-model="searchForm.tradeType"
                  multiple
                  filterable
                  clearable
                  style="width: 100%;"
                  ref="tradeType"
                  :placeholder="$t('payKindHint')"
                  @change="tradeTypeChange"
                >
                  <el-option v-for="item in tradeTypes" :key="item.category" :label="item.name" :value="item.category">
                    <div class="select-option-with-images">
                      <img class="option-image" :src="getPaymentLogos(item.name)" :alt="item.name" />
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('transactionId')">
                <el-input
                  clearable
                  v-model="searchForm.transactionId"
                  :placeholder="$t('transactionIdHint')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('bonusType')">
                <el-select v-model="searchForm.pointChargeType" style="width: 100%;">
                  <el-option :label="$t('all')" value="0"></el-option>
                  <el-option :label="$t('consumptionPointsAwarded')" value="1"></el-option>
                  <el-option :label="$t('campaignPoints')" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchQuery">{{ $t("searchReset") }}</el-button>
          <el-button class="confirm" @click="getList(1)" :disabled="loading">{{ $t("search") }}</el-button>
        </div>
      </div>
      <div class="summary-container">
        <div class="summary-title">
          {{ $t("tradeDataByDateRange") }}
        </div>
        <el-row :gutter="20">
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("tradeCount") }}</div>
              <div class="value">{{ numFormat(total) }} {{ $t("passage") }}</div>
            </div></el-col
          >
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("tradePointUseCount") }}</div>
              <div class="value">{{ numFormat(totalPointUseCount) }} {{ $t("passage") }}</div>
            </div></el-col
          >
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("tradePointGiveCount") }}</div>
              <div class="value">{{ numFormat(totalPointGiveCount) }} {{ $t("passage") }}</div>
            </div></el-col
          >
          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("tradePointUseAmount") }}</div>
              <div class="value">{{ pointFormat(totalPointUseAmount) }}</div>
            </div></el-col
          >

          <el-col :lg="6" :md="12" :xs="12" :sm="12">
            <div class="summary-item">
              <div class="key">{{ $t("tradePointGiveAmount") }}</div>
              <div class="value">{{ pointFormat(totalPointGiveAmount) }}</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <div class="panel-sub-title">
      <div class="title">{{ total }} {{ $t("tradeCountPay") }}({{ beginCount }}~{{ endCount }})</div>

      <el-button
        class="cancel"
        :loading="btnLoading"
        :disabled="btnLoading"
        @click="downloadClick"
        icon="el-icon-download"
        v-permission="'Record.RecordDownload'"
      >
        {{ $t("recordDownload") }}
      </el-button>
    </div>
    <div class="result-background">
      <div class="result-table">
        <el-table :data="tradeData" :row-class-name="rowClassName" v-loading="loading" @row-click="toDetail">
          <el-table-column prop="endTime" :label="$t('dateTime')" min-width="210">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 5px">{{ dateFormat(scope.row.endTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="transactionId" :label="$t('transactionId')" min-width="220"></el-table-column>
          <el-table-column prop="orderNo" :label="$t('orderNo')" min-width="200"></el-table-column>
          <el-table-column prop="shopName" :label="$t('store')" min-width="150"></el-table-column>
          <el-table-column prop="deviceNo" :label="$t('terminalNo')" width="150"></el-table-column>
          <el-table-column prop="tradeState" :label="$t('tradeKind')" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.tradeState === 'REFUND'">{{ $t("refund") }}</span>
              <span v-else-if="scope.row.tradeState === 'SUCCESS'">{{ $t("payment") }}</span>
              <span v-else-if="scope.row.tradeState === 'POINTUSEREFUND'">{{ $t("pointUseRefund") }}</span>
              <span v-else-if="scope.row.tradeState === 'POINTUSESUCCESS'">{{ $t("pointUseSuccess") }}</span>
              <span v-else-if="scope.row.tradeState === 'POINTGIVEREFUND'">{{ $t("pointGiveRefund") }}</span>
              <span v-else-if="scope.row.tradeState === 'POINTGIVESUCCESS'">{{ $t("pointGiveSuccess") }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="tradeTypeDisplayName" :label="$t('tradePayKind')" width="220">
            <template slot-scope="scope">
              <div class="image-text-container">
                <img :src="getPaymentLogos(scope.row.tradeTypeDisplayName)" :alt="scope.row.tradeTypeDisplayName" />
                <span>{{ scope.row.tradeTypeDisplayName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('pointAmount')" min-width="150">
            <template slot-scope="scope">{{ pointFormat(Number(scope.row.tradeAmount)) }} </template>
          </el-table-column>
          <el-table-column :label="$t('bonusType')" min-width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.pointChargeType === 'PROMOTION'">{{ $t("campaignPoints") }}</span>
              <span v-else-if="scope.row.pointChargeType === 'BONUS'">{{ $t("consumptionPointsAwarded") }}</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="150" :label="$t('operation')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="mini" type="text" @click.native.stop="toDetail(scope.row)">
                  {{ $t("detail") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        background
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
        class="common-pagination"
      >
      </el-pagination>
    </div>

    <!-- 交易详情UI开始 -->
    <side-layer :show-side-layer.sync="detailDrawer">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">{{ $t("tradeInfo") }}</div>

        <div class="dataTable">
          <div class="dataItem">
            <td
              class="name"
              v-if="detailInfo.tradeState === 'POINTUSEREFUND' || detailInfo.tradeState === 'POINTGIVEREFUND'"
            >
              {{ $t("refundDate") }}
            </td>
            <td class="name" v-else>{{ $t("payDateTime") }}</td>
            <td class="value">
              {{ dateFormat(detailInfo.endTime) }}
            </td>
          </div>

          <div class="dataItem">
            <td class="name">
              {{ $t("enterpriseCode") }}
            </td>
            <td class="value">
              {{ detailInfo.entId }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("storeCode") }}</td>
            <td class="value">
              {{ detailInfo.shopId }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("storeName") }}</td>
            <td class="value">
              {{ detailInfo.shopDisplay }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("terminalNo") }}</td>
            <td class="value">
              {{ detailInfo.deviceNo }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("tradeKind") }}
            </td>
            <td class="value">
              <span v-if="detailInfo.tradeState === 'REFUND'">{{ $t("refund") }}</span>
              <span v-else-if="detailInfo.tradeState === 'SUCCESS'">{{ $t("payment") }}</span>
              <span v-else-if="detailInfo.tradeState === 'POINTUSEREFUND'">{{ $t("pointUseRefund") }}</span>
              <span v-else-if="detailInfo.tradeState === 'POINTUSESUCCESS'">{{ $t("pointUseSuccess") }}</span>
              <span v-else-if="detailInfo.tradeState === 'POINTGIVEREFUND'">{{ $t("pointGiveRefund") }}</span>
              <span v-else-if="detailInfo.tradeState === 'POINTGIVESUCCESS'">{{ $t("pointGiveSuccess") }}</span>
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("transactionId") }}
            </td>
            <td class="value">
              {{ detailInfo.transactionId }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("tradePayKind") }}
            </td>
            <td class="value image-text-container">
              <img :src="getPaymentLogos(detailInfo.tradeTypeDisplayName)" :alt="detailInfo.tradeTypeDisplayName" />
              <span>{{ detailInfo.tradeTypeDisplayName }} </span>
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("pointAmount") }}
            </td>
            <td class="value">
              {{ pointFormat(Number(detailInfo.tradeAmount)) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("pointCard") }}</td>
            <td class="value">
              {{ detailInfo.pointCardNo }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("orderNo") }}</td>
            <td class="value">
              {{ detailInfo.orderNo }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("orderAmount") }}</td>
            <td class="value">
              {{ moneyFormat(detailInfo.orderAmount) }}
            </td>
          </div>

          <div
            class="dataItem"
            v-if="detailInfo.tradeState === 'POINTGIVESUCCESS' || detailInfo.tradeState === 'POINTGIVEREFUND'"
          >
            <td class="name">{{ $t("bonusType") }}</td>
            <td class="value">
              <span v-if="detailInfo.pointChargeType === 'PROMOTION'">{{ $t("campaignPoints") }}</span>
              <span v-else-if="detailInfo.pointChargeType === 'BONUS'">{{ $t("consumptionPointsAwarded") }}</span>
              <span v-else></span>
            </td>
          </div>
        </div>
      </div>
    </side-layer>
    <!-- 交易详情UI结束 -->
    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container">
        <div class="download-type-item" @click="downloadTradData(3)">
          <img src="~@/assets/download_type_icon/csv.svg" alt="" />
          <span>CSV</span>
        </div>
        <div class="download-type-item" @click="downloadTradData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadTradData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadTradData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import getPaymentLogos from "@/mixins/paymentLogos";

import { pointtradeTypes, downloadPointFile, pointRecordList, pointRecordDetail } from "api/trade";
import { getSessionStorage } from "@/utils/storage";
import { shopList, storeList, slaveenterprisestorelist } from "api/shop";
import { slaveEnterprises } from "api/enterprise";
import { isArray } from "lodash";
import { moneyFormat, numFormat, pointFormat } from "utils";
import { executeDownload } from "utils/download";
import { dateFormat, diffMonths } from "utils/date";
export default {
  name: "tradePoint",
  mixins: [pagination, getPaymentLogos],

  data() {
    return {
      slaveEnterprisesDatas: [],
      userInfo: {},
      tradeData: [],
      loading: false,
      btnLoading: false,
      totalPayAmount: 0,
      totalRefundAmount: 0,
      shopList: [],
      tradeTypes: [],
      searchForm: {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: "",
        entList: "",
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: "",
        pointChargeType: "0"
      },
      downloadDialog: false,
      detailDrawer: false,
      detailLoading: false,
      detailInfo: {},
      refundDialog: false,
      canRefundAmount: 0,
      refundAmount: 0,
      refundType: "all",
      refundAmountPrecision: 0,
      canPartRefund: true,
      canEditRefundFee: false,
      refundLoading: false,
      notAll: false,
      beginCount: 0,
      endCount: 0,
      currentHoverRowId: [],
      totalPointUseCount: "",
      totalPointGiveCount: "",
      totalPointUseAmount: "",
      totalPointGiveAmount: ""
    };
  },

  methods: {
    dateFormat,
    slaveEnterprises,
    numFormat,
    diffMonths,
    moneyFormat,
    pointFormat,
    tradeTypeChange() {
      let isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      this.$nextTick(() => {
        if (isMobile) this.$refs["tradeType"].blur();
      });
    },

    changeAll(value) {
      if (!value[value.length - 1]) {
        this.searchForm.shopList = "";
        this.notAll = false;
      } else {
        if (isArray(value)) {
          this.searchForm.shopList = value;
        } else {
          this.searchForm.shopList = [value];
        }
        this.notAll = true;
        if (this.userInfo.isMasterEnterprise) {
          this.getMultiTradeTypes();
        }
      }
    },
    //查询数据列表
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      let that = this;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      this.currentPage = pageIndex;
      if (diffMonths(endTime, beginTime) > 2) {
        this.$message.warning(this.$t("tradeSearchPeriodLimit"));
      } else {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(that.searchForm));
        delete data.searchDate;
        if (!isArray(data.shopList)) {
          data.shopList = [];
        }
        pointRecordList({
          pageIndex,
          pageSize,
          beginTime,
          endTime,
          ...data
        }).then(response => {
          this.loading = false;
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.tradeData = response.result.records;
            this.total = response.result.totalCount;
            this.totalPayAmount = response.result.totalPayAmount;
            this.totalPointUseCount = response.result.totalUseCount;
            this.totalPointGiveCount = response.result.totalGiveCount;
            this.totalPointUseAmount = response.result.totalUseAmount;
            this.totalPointGiveAmount = response.result.totalGiveAmount;

            if (response.result.records.length) {
              that.beginCount = (pageIndex - 1) * pageSize + 1;
              that.endCount = that.beginCount + response.result.records.length - 1;
            }
          } else {
            this.tradeData = [];
            this.total = 0;
            this.totalPayAmount = 0;
            this.totalPointUseCount = 0;
            this.totalPointGiveCount = 0;
            this.totalPointUseAmount = 0;
            this.totalPointGiveAmount = 0;
            that.beginCount = 0;
            that.endCount = 0;
          }
        });
      }
    },
    //重置查询条件
    resetSearchQuery() {
      this.searchForm = {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: "",
        entList: "",
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: "",
        pointChargeType: "0"
      };
      this.notAll = false;
      if (this.userInfo.isMasterEnterprise) {
        this.getSlaveEnterprises();
        this.getSlaveEnterpriseStoreList();
        this.getMultiTradeTypes();
      }
    },
    //请求订单详情
    toDetail(row) {
      this.detailDrawer = true;
      this.detailLoading = true;
      this.detailInfo = {};
      let { outTradeNo, tradeType, orderNo } = row;

      pointRecordDetail({ outTradeNo, tradeType, orderNo }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.detailInfo = response.result;
        }
      });
    },
    rowClassName({ row }) {
      let className = "";

      if (row.tradeState === "REFUND" || row.tradeState === "POINTUSEREFUND" || row.tradeState === "POINTGIVEREFUND")
        className += "row-refund-color";

      this.currentHoverRowId.some(item => item.groupId === row.groupId) ? (className += " group-hover-row") : "";

      return className;
    },

    getShopList() {
      if (this.$settings.newStoreVersion === true) {
        storeList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = response.result;
          }
        });
      } else {
        shopList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = [
              {
                label: this.$t("shopOffline"),
                options: []
              },
              {
                label: this.$t("shopOnline"),
                options: []
              }
            ];

            if (response.result.length > 0) {
              response.result.forEach(item => {
                if (item.isOnline) {
                  this.shopList[1].options.push(item);
                } else {
                  this.shopList[0].options.push(item);
                }
              });
            }
          }
        });
      }
    },

    getTradeTypes() {
      pointtradeTypes().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.tradeTypes = response.result;
        }
      });
    },
    downloadClick() {
      this.downloadDialog = true;
    },
    downloadTradData(fileType) {
      this.downloadDialog = false;
      this.btnLoading = true;
      downloadPointFile({
        BeginTime: this.searchForm.searchDate[0],
        EndTime: this.searchForm.searchDate[1],
        EntList: this.searchForm.entList,
        ShopList: this.searchForm.shopList,
        TradeType: this.searchForm.tradeType,
        TradeState: this.searchForm.tradeState,
        DeviceNo: this.searchForm.deviceNo,
        orderNo: this.searchForm.orderNo,
        transactionId: this.searchForm.transactionId,
        pointChargeType: this.searchForm.pointChargeType,
        fileFormat: fileType
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.btnLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.btnLoading = false;
        }
      });
    },
    // 查询多企业多店铺支付方式列表
    getMultiTradeTypes() {
      let param = { entIdList: this.searchForm.entList, storeIdList: this.searchForm.shopList };
      pointtradeTypes(param).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.tradeTypes = response.result;
        }
      });
    },
    // 获取父子企业数据
    getSlaveEnterprises() {
      slaveEnterprises().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.slaveEnterprisesDatas = response.result;
        } else {
          this.slaveEnterprisesDatas = [];
        }
      });
    },
    // 获取子企业店铺
    getSlaveEnterpriseStoreList(entList) {
      slaveenterprisestorelist({ entList }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.shopList = response.result;
        }
      });
    },
    // 企业选择事件
    entChange(value) {
      this.searchForm.shopList = "";
      this.notAll = false;
      if (!value[value.length - 1]) {
        this.getSlaveEnterpriseStoreList();
      } else {
        this.getSlaveEnterpriseStoreList(value);
      }
      this.getMultiTradeTypes();
    }
  },
  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    if (this.userInfo.isMasterEnterprise) {
      this.getSlaveEnterprises();
      this.getSlaveEnterpriseStoreList();
      this.getMultiTradeTypes();
    } else {
      this.getShopList();
      this.getTradeTypes();
    }
  }
};
</script>
